<template>
  <div>
    <div style="margin-top: 10px">
      <van-field
        v-model="positionFrom.Title"
        label="职位名称"
        required
        placeholder="请输入职位名称"
      />
      <van-field
        v-model="positionFrom.AbilityName"
        label="职业类型"
        required
        placeholder="请选择职业类型"
        @click="AbilityCode = true"
        readonly="readonly"
      />
      <van-popup v-model="AbilityCode" position="bottom" get-container="body">
        <van-cascader
          title="请选择职业类型"
          :options="zhiye"
          @close="AbilityCode = false"
          :field-names="{ text: 'Name', value: 'Coding', children: 'Children' }"
          @finish="onAbilityCode"
        />
      </van-popup>
      <van-field
        v-model="positionFrom.RecruitNum"
        label="招聘人数"
        type="digit"
        placeholder="请输入招聘人数 默认为不限人数"
      />
      <van-field
        v-model="positionFrom.WorkYear"
        label="工作年限"
        type="digit"
        placeholder="请输入工作年限 默认为不限年份"
      />
      <van-field
        v-model="positionFrom.SalaryMin"
        label="最低月薪"
        required
        type="digit"
        placeholder="请输入最低月薪"
      />
      <van-field
        v-model="positionFrom.SalaryMax"
        label="最高月薪"
        required
        type="digit"
        placeholder="请输入最高月薪"
      />
      <van-field
        v-model="positionFrom.AgeMin"
        label="最小年龄"
        type="digit"
        placeholder="请输入最小年龄"
      />
      <van-field
        v-model="positionFrom.AgeMax"
        label="最大年龄"
        type="digit"
        placeholder="请输入最大年龄"
      />
      <van-field
        v-model="positionFrom.EduName"
        label="学历"
        placeholder="请选择学历 默认为不限学历"
        @click="Education = true"
        readonly="readonly"
      />
      <van-popup v-model="Education" position="bottom" get-container="body">
        <van-picker
          show-toolbar
          :columns="xveli"
          value-key="Name"
          @cancel="Education = false"
          @confirm="onEducation"
        >
        </van-picker>
      </van-popup>
      <van-field
        v-model="positionFrom.UrgencyDesc"
        label="紧急状态"
        placeholder="请选择紧急状态"
        @click="Urgency = true"
        readonly="readonly"
      />
      <van-popup v-model="Urgency" position="bottom" get-container="body">
        <van-picker
          show-toolbar
          :columns="jinji"
          @cancel="Urgency = false"
          @confirm="onUrgency"
        >
        </van-picker>
      </van-popup>
      <van-field
        v-model="positionFrom.StatusDesc"
        label="状态"
        required
        placeholder="请选择状态"
        @click="Status = true"
        readonly="readonly"
      />
      <van-popup v-model="Status" position="bottom" get-container="body">
        <van-picker
          show-toolbar
          :columns="zhuangtai"
          @cancel="Status = false"
          @confirm="onStatus"
        >
        </van-picker>
      </van-popup>
      <van-field
            v-model="positionFrom.WorkAddrName"
            label="工作地点"
            required
            placeholder="请选择工作地点"
            @click="WorkAddrCode = true"
            readonly="readonly"
          />
          <van-popup
            v-model="WorkAddrCode"
            position="bottom"
            get-container="body"
          >
            <van-cascader
              title="请选择工作地点"
              :options="NativeCantonCodedata"
              @close="WorkAddrCode = false"
              :field-names="{
                text: 'text',
                value: 'value',
                children: 'children',
              }"
              @finish="onWorkAddrCode"
            />
          </van-popup>
      <van-field
        v-model="positionFrom.SalaryMemo"
        rows="1"
        autosize
        label="薪资备注"
        type="textarea"
        placeholder="请输入薪资备注"
      />
      <van-field
        v-model="positionFrom.Describe"
        rows="1"
        autosize
        label="职位描述"
        required
        type="textarea"
        placeholder="请输入职位描述"
      />
      <van-field
        v-model="positionFrom.Skill"
        rows="1"
        autosize
        label="技能要求"
        required
        type="textarea"
        placeholder="请输入技能要求"
      />
      <van-field
        v-model="positionFrom.JobRequire"
        rows="1"
        autosize
        label="任职要求"
        required
        type="textarea"
        placeholder="请输入任职要求"
      />
      <van-field
        v-model="positionFrom.Welfare"
        rows="1"
        autosize
        label="职位福利"
        type="textarea"
        placeholder="请输入职位福利"
      />
      <div class="fromButton">
        <!-- <van-cell title="招聘海报" is-link to="/list/company/imgs" /> -->
        <van-button @click="SaveJob" round type="info">提 交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from 'vant';
Vue.use(Toast);
Vue.use(Dialog);
import { RE_SaveJob, GetAbilityList } from "@/api/REplat";
import { GetCodeEntrysList,WeGetJobDetail } from "@/api/ResumeJS";
import { getaccToken, setaccToken } from "@/utils/auth";
export default {
  data() {
    return {
      positionFrom: {
        //保存
        JId: 0, //职位Id、
        Title: "", //职位名称、
        AbilityCode: "", //职业类型编码、
        RecruitNum: "", //招聘人数、
        WorkAddrCode: "", //工作地点编码，可多值，以逗号隔开、
        WorkYear: "", //工作年限、
        EduCode: "", //学历编码、
        SalaryMin: "", //最低月薪、
        SalaryMax: "", //最高月薪、
        SalaryMemo: "", //薪资备注、
        AgeMin: "", //最小年龄、
        AgeMax: "", //最大年龄、
        Describe: "", //职位描述、
        Skill: "", //技能要求、
        JobRequire: "", //任职要求、
        Urgency: "", //紧急程度（1一般、2紧急、3非常紧急）、
        Welfare: "", //职位福利、
        Status: "", //状态（1招聘中、2暂缓招聘、3招聘结束）、
        Remark: "", //备注
        accToken:getaccToken(),
      },
      // 选择器
      zhiye: [],
      xveli: [],
      NativeCantonCodedata: [],
      jinji: [
        { id: 1, text: "一般" },
        { id: 2, text: "紧急" },
        { id: 3, text: "非常紧急" },
      ],
      zhuangtai: [
        { id: 1, text: "招聘中" },
        { id: 1, text: "暂缓招聘" },
        { id: 1, text: "招聘结束" },
      ],
      //选择器显示与隐藏
      Education: false,
      Status: false,
      Urgency: false,
      AbilityCode: false,
      WorkAddrCode:false,
      Jidpost:undefined,
    };
  },
  created() {
    this.Jidpost = this.$router.currentRoute.query.ID;
    if(this.Jidpost != undefined){
      this.JobDetail();
    }
    this.zidianlist();
  },
  methods: {
    SaveJob: function () {
      if(this.positionFrom.Title == ""){
        Toast.fail('职位名称不能为空!');
        return false;
      }
      if(this.positionFrom.AbilityCode == ""){
        Toast.fail('职位类型不能为空!');
        return false;
      }
      if(this.positionFrom.Status == ""){
        Toast.fail('请选择招聘状态!');
        return false;
      }
      if(this.positionFrom.WorkAddrCode == ""){
        Toast.fail('请选择工作地点!');
        return false;
      }
      if(this.positionFrom.SalaryMin == ""){
        Toast.fail('最低月薪不能为空!');
        return false;
      }
      if(this.positionFrom.SalaryMax == ""){
        Toast.fail('最高月薪不能为空!');
        return false;
      }
      if(this.positionFrom.Describe == ""){
        Toast.fail('职位描述不能为空!');
        return false;
      }
      if(this.positionFrom.Skill == ""){
        Toast.fail('技能要求不能为空!');
        return false;
      }
      if(this.positionFrom.JobRequire == ""){
        Toast.fail('任职要求不能为空!');
        return false;
      }
      //保存职位
      RE_SaveJob(this.positionFrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.push({path: "/list/firmlogin/jobList",});
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!"  + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    // 获取职位详情
    JobDetail(){
      WeGetJobDetail({jId:this.Jidpost}).then((res)=>{
        console.log(res);
        
        this.positionFrom = res.data.data;
        this.positionFrom.accToken = getaccToken();

      })
    },
    // 字典
    zidianlist: function () {
      // 学历
      GetCodeEntrysList({ kind: "5" }).then((res) => {
        this.xveli = res.data.data;
      });
      GetAbilityList().then((res) => {
        this.zhiye = res.data.data;
      });
      // 地区
      GetCodeEntrysList({ kind: "2" }).then((res) => {
        var Citys = [],
          Citysx = [],
          Citysq = [],
          Citysqp = [],
          list = res.data.data;
        for (var i = 0; i < list.length; i++) {
          if (list[i].Coding.length == "2") {
            Citys.push({
              value: list[i].Coding,
              children: [],
              text: list[i].Alias,
            });
          } else if (list[i].Coding.length == "4") {
            Citysx.push({
              value: list[i].Coding,
              children: [],
              text: list[i].Alias,
            });
          } else if (list[i].Coding.length == "6") {
            if (list[i].Coding == "410324") {
              Citysq.push({
                value: list[i].Coding,
                text: list[i].Alias,
                children: [],
              });
            } else {
              Citysq.push({ value: list[i].Coding, text: list[i].Alias });
            }
          } else if (list[i].Coding.length > "6") {
            if (list[i].Coding.substr(0, 6) == "410324") {
              Citysqp.push({ value: list[i].Coding, text: list[i].Alias });
            }
          }
        }
        for (var p = 0; p < Citysq.length; p++) {
          if (Citysq[p].value.substr(0, 6) == "410324") {
            Citysq[p].children = Citysqp;
          }
        }
        for (var i = 0; i < Citysq.length; i++) {
          for (var j = 0; j < Citysx.length; j++) {
            if (Citysq[i].value.substr(0, 4) == Citysx[j].value) {
              Citysx[j].children.push(Citysq[i]);
            }
          }
        }
        for (var z = 0; z < Citysx.length; z++) {
          for (var y = 0; y < Citys.length; y++) {
            if (Citysx[z].value.substr(0, 2) == Citys[y].value) {
              Citys[y].children.push(Citysx[z]);
            }
          }
        }
        this.NativeCantonCodedata = Citys;
        this.NativeCantonCodedataCs = Citys;
      });
    },
    onEducation(val) {
      //学历选择器
      this.positionFrom.EduCode = val.Coding;
      this.positionFrom.EduName = val.Name;
      this.Education = false;
    },
    onUrgency(val) {
      //紧急情况选择器
      this.positionFrom.Urgency = val.id;
      this.positionFrom.UrgencyDesc = val.text;
      this.Urgency = false;
    },
    onStatus(val) {
      //状态况选择器
      this.positionFrom.Status = val.id;
      this.positionFrom.StatusDesc = val.text;
      this.Status = false;
    },
    onAbilityCode(val) {
      //状态况选择器
      if(val.selectedOptions.length == 1){
        this.positionFrom.AbilityCode = val.selectedOptions[0].Coding;
        this.positionFrom.AbilityName = val.selectedOptions[0].Name;
      }else{
        this.positionFrom.AbilityCode = val.selectedOptions[1].Coding;
        this.positionFrom.AbilityName = val.selectedOptions[1].Name;
      }
      this.AbilityCode = false;
    },
    onWorkAddrCode: function (value) { //工作地点
      var RegCity = this.addressfrom(value)
      this.positionFrom.WorkAddrCode = RegCity[0];
      this.positionFrom.WorkAddrName = RegCity[1];
      this.WorkAddrCode = false;
    },
    addressfrom(value){ //地址
      var a,b,c;
      a = value.selectedOptions[0];
      b = value.selectedOptions[1];
      c = value.selectedOptions[2];
      var addressCode = c.value;
      var addressName = a.text + ',' + b.text + ',' + c.text;
      var address = [addressCode,addressName]
      return address
    },
  },
};
</script>
<style scoped>
.haibao {
  margin: 10px 10px;
}
</style>